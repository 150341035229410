<template>
  <div>
    <vs-popup class="sm:popup-w-50" :title="btnText + ' PO Kontrak'" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Tgl</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-calendar" v-model="data.tgl" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Status</label>
            <vs-select v-model="data.status" class="w-full" disabled>
              <vs-select-item v-for="(it, index) in listStatus" :key="index" :value="it.value" :text="it.name"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full" v-model="data.keterangan"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" :color="btnColor" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" :color="btnColor" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">{{ btnText }}</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import PoKontrakApprovalRepository from '@/repositories/approvals/po-kontrak-approval-repository'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'PoKontrakApprovalSigning',
  props: ['isActive', 'idPo', 'status'],
  components: {
    ValidationErrors
  },
  computed: {
    listStatus () {
      return [{ name: 'APPROVE', value: 'DITERIMA' }, { name: 'REVISI', value: 'DIREVISI' }, { name: 'REJECT', value: 'DITOLAK' }]
    },
    btnColor () {
      return this.status === 'DITERIMA' ? 'success' : (this.status === 'DIREVISI' ? 'warning' : (this.status === 'DITOLAK' ? 'danger' : null))
    },
    btnText () {
      return this.status === 'DITERIMA' ? 'Approve' : (this.status === 'DIREVISI' ? 'Revisi' : (this.status === 'DITOLAK' ? 'Reject' : null))
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {
        tgl: moment().format('YYYY-MM-DD'),
        id_po: null,
        status: null
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data.id_po = this.idPo
        this.data.status = this.status
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      PoKontrakApprovalRepository.signing(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Kontrak PO berhasil ditanggapi.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.$store.dispatch('general/navbarBadge/getDataForMenuApproval')
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
